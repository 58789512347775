<template>
  <v-app class="p-6">
      <div class="row">
        <div class="col-md-12 p-0 pt-2 pr-3">
            <div 
                v-if="currentUser.enable_mfa_required"
                class="alert alert-danger py-4"
            >
                <div class="text-white font-weight-bolder">
                    <i class="fa fa-warning" style="color:#fff;"></i>
                    <span class="text-h6">
                        You have not enabled MFA, please enable MFA before {{ currentUser.formatted_mfa_deadline_date }}.
                    </span>
                    <a href="#" @click="goToUserProfile()" class="text-h6 text-white" style="text-decoration:underline;">Enable MFA</a>
                </div>
            </div>
        </div>
          <div class="col-md-8">
              <div class="row">
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{ name: 'manual-enrolment-step-1' }">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/manual_enrolment.png" style="height: 64px"/>
                              <p>Manual enrolment</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{ name: 'accountholder' }" >
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/enroller_add.png" style="height: 64px"/>
                              <p>Add enroller</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{name:'exam-day'}" >
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/schedule.png" style="height: 64px"/>
                              <p>Schedule</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{name:'start-scheduling'}">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/start_scheduling.png" style="height: 64px"/>
                              <p>Start Schedule</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link to="/merge/account-holders">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/transfer_enrolment.png" style="height: 64px"/>
                              <p>Transfer enrolment</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{name:'pending-timetable-print'}">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/timetable_print.png" style="height: 64px"/>
                              <p>Timetable Printer</p>
                          </div>
                      </router-link>
                  </div>

                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{name:'pending-print'}">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/normal_print.png" style="height: 64px"/>
                              <p>Printing</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{name: 'exam-session'}">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/exam_sessions.png" style="height: 64px"/>
                              <p>Exam {{ currentUser.session_default_text }}</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link to="/children-check">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/child_safety.png" style="height: 64px"/>
                              <p>Child safety</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link :to="{ name: 'venue' }">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/venues.png" style="height: 64px"/>
                              <p>Venues</p>
                          </div>
                      </router-link>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link  :to="{ name: 'location' }">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/locations.png" style="height: 64px"/>
                              <p>Locations</p>
                          </div>
                      </router-link>
                  </div>

                  <div class="col-xl-2 col-lg-2 col-md-2 text-center dashboard-quick-links border border-gray">
                      <router-link  :to="{ name: 'support-ticket', params: { type: 'all' } }">
                          <div class="col-md-12 dashboard-quick-icons">
                              <img src="/media/dashboard/tickets.png" style="height: 64px"/>
                              <p>Tickets</p>
                          </div>
                      </router-link>
                  </div>
              </div>
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-4">
                    <router-link to="/account-holder" class="card-xl-stretch  counter-box bg-counter-1 card hoverable p-5">
                        <div class="">
                            <!--                      <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                          <i class="fas fa-users font-white"></i>-->
                            <!--                      </span>-->
                            <div class="text-inverse-primary font-counter-title-bg-1 fw-semibold fs-7">Enrollers</div>
                            <div class="text-inverse-primary fw-bold font-counter-bg-counter-1 mb-2 mt-3">{{ countStats.enroller_count }}</div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                    <router-link to="/examiner" class="card-xl-stretch  counter-box bg-counter-2 card hoverable p-5">
                        <div class="">
                            <!--                      <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                          <i class="fas fa-user-graduate font-white"></i>-->
                            <!--                      </span>-->
                            <div class="text-inverse-primary font-counter-title-bg-2 fw-semibold fs-7">Examiners</div>
                            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-3 font-counter-bg-counter-2 ">{{ countStats.examiner_count }}</div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                    <router-link to="/supervisor" class="card-xl-stretch  counter-box bg-counter-1 card hoverable p-5">
                        <div class="">
                            <!--                      <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                          <i class="fas fa-user-clock font-white"></i>-->
                            <!--                      </span>-->
                            <div class="text-inverse-primary font-counter-title-bg-1 fw-semibold fs-7">Supervisors</div>
                            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-3 font-counter-bg-counter-1 ">{{ countStats.supervisor_count }}</div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4" v-if="user.access_type=='federal'">
                    <router-link to="/online-marker" class="card-xl-stretch  counter-box bg-counter-2 card hoverable p-5" >
                        <div class="">
                            <!--                      <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                          <i class="fas fa-user-clock font-white"></i>-->
                            <!--                      </span>-->
                            <div class="text-inverse-primary font-counter-title-bg-2 fw-semibold fs-7">Markers</div>
                            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-3 font-counter-bg-counter-2 ">{{ countStats.online_marker_count }}</div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                    <div class="card-xl-stretch  counter-box  card bg-counter-2 hoverable p-5">
                        <div class="" style="cursor:pointer" @click="gotoLink('practical')">
                            <div class="text-inverse-primary fw-semibold fs-7 font-counter-title-bg-2">Practical exam candidates</div>
                            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-3 font-counter-bg-counter-2 ">{{ practicalExamCandidatesCount }}</div>
                        </div>
                        <div class="d-flex text-right">
                            <!--                    <span class="mt-2 svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                        <i class="fas fa-chalkboard-teacher font-white"></i>-->
                            <!--                    </span>-->

                            <v-select
                                outlined
                                dense
                                x-small
                                label="Year"
                                class="pull-right"
                                style="max-width: 105px"
                                @change="getPracticalExamCandidatesCount"
                                :items="enrolmentYears"
                                item-text="year"
                                item-value="id"
                                :loading="isPracticalExamLoading"
                                v-model="practicalExamCandidateYear"
                            ></v-select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                    <div class="card-xl-stretch  counter-box  card bg-counter-1 hoverable p-5">


                        <div class="" style="cursor:pointer" @click="gotoLink('ameb-award')">
                            <div class="text-inverse-primary fw-semibold fs-7 font-counter-title-bg-1">AMEB award candidates</div>
                            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-3 font-counter-bg-counter-1 ">{{ amebAwardCandidatesCount }}</div>
                        </div>
                        <div class="d-flex justify-space-between">
                            <!--                    <span class="mt-2 svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                        <i class="fas fa-medal font-white"></i>-->
                            <!--                    </span>-->

                            <v-select
                                outlined
                                dense
                                small
                                label="Year"
                                style="max-width: 105px"
                                :items="enrolmentYears"
                                @change="getAmebAwardCandidatesCount"
                                item-text="year"
                                item-value="id"
                                v-model="amebAwardCandidateYear"
                                :loading="isAmebAwardLoading"
                            ></v-select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                    <div class="card-xl-stretch  counter-box  card bg-counter-2 hoverable p-5">


                        <div class="" style="cursor:pointer" @click="gotoLink('course')">
                            <div class="text-inverse-primary fw-semibold fs-7 font-counter-title-bg-2">Course candidates</div>
                            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-3 font-counter-bg-counter-2">{{ onlineCourseCandidatesCount }}</div>
                        </div>
                        <div class="d-flex justify-space-between">
                            <!--                    <span class="mt-2 svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                        <i class="fas fa-laptop-file font-white"></i>-->
                            <!--                    </span>-->

                            <v-select
                                outlined
                                dense
                                small
                                label="Year"
                                class="small-filter-select"
                                :items="enrolmentYears"
                                item-text="year"
                                item-value="id"
                                style="max-width: 105px"
                                @change="getOnlineCourseCandidatesCount"
                                v-model="onlineCourseCandidateYear"
                                :loading="isOnlineCourseLoading"
                            ></v-select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4">
                    <div class="card-xl-stretch  counter-box  card bg-counter-1 hoverable p-5">


                        <div class="" style="cursor:pointer" @click="gotoLink('exam')">
                            <div class="text-inverse-primary fw-semibold fs-7 font-counter-title-bg-1">Online exam candidates</div>
                            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-3 font-counter-bg-counter-1 ">{{ onlineExamCandidatesCount }}</div>
                        </div>
                        <div class="d-flex justify-space-between">
                            <!--                    <span class="mt-2 svg-icon-white svg-icon svg-icon-3x mx-n1">-->
                            <!--                        <i class="fas fa-computer font-white"></i>-->
                            <!--                    </span>-->

                            <v-select
                                outlined
                                dense
                                small
                                @change="getOnlineExamCandidatesCount"
                                v-model="examCandidateYear"
                                label="Year"
                                class="small-filter-select"
                                style="max-width: 105px"
                                :items="enrolmentYears"
                                item-text="year"
                                item-value="id"
                                :loading="isOnlineExamLoading"
                            ></v-select>
                        </div>
                    </div>
                </div>
            </div>
              <div class="row">
                  <div class="col-md-4">
                      <div class="dashboard-border">
                          <div class="d-flex justify-space-between">
                              <div class="font-weight-bold text-h6 mt-2">
                                  Enrolment summary
                              </div>
                              <div>
                                  <v-select
                                      style="max-width: 105px"
                                      outlined
                                      dense
                                      density="compact"
                                      @change="getEnrolmentStats"
                                      v-model="enrolmentStatusYear"
                                      :items="enrolmentYears"
                                      label="Year"
                                      class="small-filter-select"
                                      item-text="year"
                                      item-value="id"
                                      :loading="loadingCandidateYear"
                                  ></v-select>
                              </div>
                          </div>
                          <v-skeleton-loader
                              v-if="loadingCandidateYear"
                              type="list-item@8"
                          >
                          </v-skeleton-loader>
                          <table class="table" v-if="!loadingCandidateYear">
                              <thead>
                              <tr>
                                  <th>Description</th>
                                  <th>Stats</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                  <td>
                                      Total enrolled candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-info">{{ totalEnrolledCandidates }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total assessed candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-info">  {{ totalAssessedCandidates }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total cancelled candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-info"> {{ totalCancelledCandidates }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total withdrawn candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-info">{{ totalWithdrawnCandidates }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                     Total no show candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-primary">{{ totalNoShowCandidates }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total scheduled candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-success"> {{ totalScheduledCandidates }} </span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total void candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-danger"> {{ totalVoidCandidates }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total payment pending candidates
                                  </td>
                                  <td>
                                      <span class="badge badge-warning"> {{ totalPendingPaymentCandidates }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total unscheduled candidates
                                  </td>
                                  <td>
                                     <span class="badge badge-danger">{{ totalUnscheduledCandidates }}</span>
                                  </td>
                              </tr>
                              </tbody>

                          </table>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="dashboard-border">
                          <div class="d-flex justify-space-between">
                              <div class="font-weight-bold text-h6 mt-2">
                                  Schedules summary
                              </div>
                              <div>
                                  <v-select
                                      outlined
                                      dense
                                      style="max-width: 105px"
                                      small
                                      @change="getExamDayStats"
                                      v-model="scheduleYear"
                                      :items="enrolmentYears"
                                      label="Year"
                                      class="small-filter-select"
                                      item-text="year"
                                      item-value="id"
                                      :loading="loadingScheduleYear"
                                  ></v-select>
                              </div>
                          </div>
                          <v-skeleton-loader
                              v-if="loadingScheduleYear"
                              type="list-item@3"
                          >
                          </v-skeleton-loader>
                          <table class="table" v-if="!loadingScheduleYear">
                              <tbody>
                              <tr>
                                  <td>
                                      Total schedules
                                  </td>
                                  <td>
                                      <span class="badge badge-primary">{{ totalSchedules }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total confirmed schedules
                                  </td>
                                  <td>
                                      <span class="badge badge-success"> {{ totalConfirmedSchedules }} </span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total unconfirmed schedules
                                  </td>
                                  <td>
                                      <span class="badge badge-warning">{{ totalNotConfirmedSchedules }}</span>
                                  </td>
                              </tr>
                              </tbody>

                          </table>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="dashboard-border">
                          <div class="d-flex justify-space-between">
                              <div class="font-weight-bold text-h6 mt-2">
                                  Venue & locations
                              </div>
                              <div>
                                  <v-select
                                      outlined
                                      dense
                                      style="max-width: 105px"
                                      small
                                      @change="getVenueStats"
                                      v-model="venueYear"
                                      :items="enrolmentYears"
                                      label="Year"
                                      class="small-filter-select"
                                      item-text="year"
                                      item-value="id"
                                      :loading="loadingVenueYear"
                                  ></v-select>
                              </div>
                          </div>
                          <v-skeleton-loader
                              v-if="loadingVenueYear"
                              type="list-item@3"
                          >
                          </v-skeleton-loader>
                          <table class="table" v-if="!loadingVenueYear">
                              <tbody>
                              <tr>
                                  <td>
                                      Total venues
                                  </td>
                                  <td>
                                      <span class="badge badge-primary">{{ totalVenues }}</span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total rooms
                                  </td>
                                  <td>
                                      <span class="badge badge-success"> {{ totalRooms }} </span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Total locations
                                  </td>
                                  <td>
                                      <span class="badge badge-warning">{{ totalLocations }}</span>
                                  </td>
                              </tr>
                              </tbody>

                          </table>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="row">
                  <div class="col-md-12">
                      <div class="dashboard-border">
                          <div class="d-flex justify-space-between">
                              <div class="text-inverse-primary fw-bold fs-7 font-counter-title-bg-1">Unscheduled candidates</div>
                              <v-select
                                  outlined
                                  dense
                                  x-small
                                  @change="getCategories"
                                  v-model="categoryFilterYear"
                                  label="Year"
                                  :items="enrolmentYears"
                                  item-text="year"
                                  style="max-width: 105px"
                                  class="small-filter-select"
                                  item-value="id"
                                  :loading="loadingCategoryTree"
                              ></v-select>
                          </div>
                          <v-skeleton-loader
                              v-if="loadingCategoryTree"
                              type="list-item-two-line@8"
                          >
                          </v-skeleton-loader>
                          <v-treeview
                              v-else
                              activatable
                              color="warning"
                              :items="treeItems"
                          >
                          </v-treeview>
                      </div>

                  </div>
              </div>
          </div>

      </div>

  </v-app>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import StateService from "@/services/setting/state/StateService";
import DashboardService from "@/services/dashboard/DashboardService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import CategoryService from "@/services/product/category/CategoryService";

import {mapActions} from "vuex";
const state = new StateService();
const dashboard = new DashboardService();
const enrolmentYear = new EnrolmentYearService();
const category = new CategoryService();

export default {
  name: "dashboard",
  components: {

  },
  data() {
    return {
      stats: {},
      programs: [],
      search: {
        name: '',
        type: 'student',
        limit: 25,

      },
      filter:null,
      states:[],
      info:{},
      countStats: '',
      practicalExamCandidatesCount: '',
      onlineExamCandidatesCount: '',
      onlineCourseCandidatesCount: '',
      amebAwardCandidatesCount: '',
      unscheduledCandidatesCount: '',
      enrolmentYears: [],
      examCandidateYear: '',
      practicalExamCandidateYear: '',
      onlineCourseCandidateYear: '',
      amebAwardCandidateYear: '',
      unscheduledCandidateYear: '',
      isPracticalExamLoading: false,
      isOnlineExamLoading: false,
      isAmebAwardLoading: false,
      isOnlineCourseLoading: false,
      isUnscheduledCandidatesLoading: false,
      categoryFilterYear: '',
      treeItems: [],
      loadingCategoryTree: true,
      totalEnrolledCandidates: null,
      totalCancelledCandidates: null,
      totalWithdrawnCandidates: null,
      totalNoShowCandidates: null,
      totalScheduledCandidates: null,
      totalAssessedCandidates: null,
      totalVoidCandidates: null,
      totalPendingPaymentCandidates: null,
      totalUnscheduledCandidates: null,
      enrolmentStatusYear: null,
      loadingCandidateYear: false,
      totalSchedules: null,
      totalConfirmedSchedules: null,
      totalNotConfirmedSchedules: null,
      loadingScheduleYear: false,
      scheduleYear: null,
      venueYear: null,
      loadingVenueYear: false,
      totalVenues: null,
      totalLocations: null,
      totalRooms: null
    };
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    ...mapActions([
        'GET_STATS'
    ]),
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getStats() {
      enrollmentService.getStats(this.user.id).then(response => {
        this.stats = response.data;
      });
    },
    getDashboardStats(){
      dashboard
      .getStats()
      .then((res) => {
        this.countStats = res.data;
      })
      .catch(err => {

      })
    },
    getStates(){
      state
          .getAllState()
          .then((response) => {
            this.states = response.data;
          })
          .catch((error) => {

          })
    },
    filterCount(){
      dashboard
      .getInfo(this.filter)
          .then(response => {
            this.info=response.data[0];
          });
    },

    goToUserProfile()
    {
      this.$router.push({name:'profile'});
    },  

    getPracticalExamCandidatesCount(){
      this.isPracticalExamLoading = true;

      let data = {
        enrolment_year_id: this.practicalExamCandidateYear
      };

      dashboard
      .getPracticalExamCandidatesCount(data)
      .then((res) => {
        this.practicalExamCandidatesCount = res.data.practicalExamCandidatesCount;
        this.isPracticalExamLoading = false;
      })
    },

    getOnlineExamCandidatesCount(){
      this.isOnlineExamLoading = true;
      
      let data = {
        enrolment_year_id: this.examCandidateYear
      };

      dashboard
      .getOnlineExamCandidatesCount(data)
      .then((res) => {
        this.onlineExamCandidatesCount = res.data.onlineExamCandidatesCount;
        this.isOnlineExamLoading = false;
      })
    },

    getOnlineCourseCandidatesCount(){
      this.isOnlineCourseLoading = true;

      let data = {
        enrolment_year_id: this.onlineCourseCandidateYear
      };

      dashboard
      .getOnlineCourseCandidatesCount(data)
      .then((res) => {
        this.onlineCourseCandidatesCount = res.data.onlineCourseCandidatesCount;
        this.isOnlineCourseLoading = false;
      })
    },

    getAmebAwardCandidatesCount(){
      this.isAmebAwardLoading = true;

      let data = {
        enrolment_year_id: this.amebAwardCandidateYear
      };

      dashboard
      .getAmebAwardCandidatesCount(data)
      .then((res) => {
        this.amebAwardCandidatesCount = res.data.amebAwardCandidatesCount;
        this.isAmebAwardLoading = false;
      })
    },

    getUnscheduledCandidatesCount(){
      this.isUnscheduledCandidatesLoading = true;

      let data = {
        enrolment_year_id: this.unscheduledCandidateYear
      };

      dashboard
      .getUnscheduledCandidatesCount(data)
      .then((res) => {
        this.unscheduledCandidatesCount = res.data.unscheduledCandidatesCount;
        this.isUnscheduledCandidatesLoading = false;
      })
    },

    getAllEnrolmentYears(){
      enrolmentYear
      .all()
      .then((res) => {
        this.enrolmentYears = res.data;
      })
    },

    gotoLink(link){
      this.$router.push({
        name: 'candidate-type',
        params: {type: link}
      });
    },

    gotoUnscheduledCandidates(){
      this.$router.push({
        name: 'unscheduled-candidates',
      });
    },

    getCategories(){
      this.loadingCategoryTree = true;

      let data = {
        enrolment_year_id: this.categoryFilterYear
      };
      
      category
      .getCategoryTree(data)
      .then((res) => {
        this.treeItems = res.data;
      })
      .catch((err) => {

      })
      .finally(() => {
        this.loadingCategoryTree = false;
      })
    },

    getEnrolmentStats(){
      this.loadingCandidateYear = true;

      let data = {
        enrolment_year_id: this.enrolmentStatusYear
      };

      dashboard
      .getEnrolmentStats(data)
      .then((res) => {
        this.totalEnrolledCandidates = res.data.data.totalEnrolledCandidates;
        this.totalCancelledCandidates = res.data.data.totalCancelledCandidates;
        this.totalWithdrawnCandidates = res.data.data.totalWithdrawnCandidates;
        this.totalNoShowCandidates = res.data.data.totalNoShowCandidates;
        this.totalScheduledCandidates = res.data.data.totalScheduledCandidates;
        this.totalAssessedCandidates = res.data.data.totalAssessedCandidates;
        this.totalVoidCandidates = res.data.data.totalVoidCandidates;
        this.totalPendingPaymentCandidates = res.data.data.totalPendingPaymentCandidates;
        this.totalUnscheduledCandidates = res.data.data.totalUnscheduledCandidates;
        this.loadingCandidateYear = false;
      })
      .catch((err) => {

      });
    },

    getExamDayStats(){
      this.loadingScheduleYear = true;

      let data = {
        enrolment_year_id: this.scheduleYear
      };

      dashboard
      .getExamDayStats(data)
      .then((res) => {
        this.totalSchedules = res.data.data.totalExamDays;
        this.totalConfirmedSchedules = res.data.data.totalConfirmedExamDays;
        this.totalNotConfirmedSchedules = res.data.data.totalNotConfirmedExamDays;
        this.loadingScheduleYear = false;
      })
      .catch((err) => {

      });
    },

    getVenueStats(){
      this.loadingVenueYear = true;

      let data = {
        enrolment_year_id: this.venueYear
      };

      dashboard
      .getVenueStats(data)
      .then((res) => {
        this.totalVenues = res.data.data.totalVenues;
        this.totalRooms = res.data.data.totalRooms;
        this.totalLocations = res.data.data.totalLocations;
        this.loadingVenueYear = false;
      })
      .catch((err) => {

      });
    },

    setCurrentYear(){
      enrolmentYear
      .currentActiveYear()
      .then((res) => {
        this.practicalExamCandidateYear = res.data.data.id;
        this.amebAwardCandidateYear = res.data.data.id;
        this.onlineCourseCandidateYear = res.data.data.id;
        this.examCandidateYear = res.data.data.id;
        this.unscheduledCandidateYear = res.data.data.id;
        this.categoryFilterYear = res.data.data.id;
        this.enrolmentStatusYear = res.data.data.id;
        this.scheduleYear = res.data.data.id;
        this.venueYear = res.data.data.id;
        this.getDashboardStats();
        this.getAllEnrolmentYears();
        this.getPracticalExamCandidatesCount();
        this.getOnlineExamCandidatesCount();
        this.getOnlineCourseCandidatesCount();
        this.getAmebAwardCandidatesCount();
        this.getUnscheduledCandidatesCount();
        this.getCategories();
        this.getEnrolmentStats();
        this.getExamDayStats();
        this.getVenueStats();
      })
      .catch((err) => {

      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
    this.GET_STATS();
    this.setCurrentYear();
    // this.getStates();
    // this.filterCount();
  },

};
</script>

<style scoped>
.theme--light.v-application {
  background: none !important;
  /*color: rgba(0, 0, 0, 0.87);*/
}
</style>